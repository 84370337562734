import React, {useState} from "react"
import {Link} from "gatsby"
import style from "./nav.module.scss"

import AleDealerships from '../images/icons/menu_AleDealerships.svg'
import AleMexJet from '../images/icons/menu_AleMexjet.svg'
import AleFBO from '../images/icons/menu_AleFBO.svg'
import AleMarine from '../images/icons/menu_AleMarine.svg'
import AlePreowned from '../images/icons/menu_AlePreowned.svg'
import AleService from '../images/icons/menu_AleService.svg'
import AleContact from '../images/icons/menu_Contact.svg'
import Home from '../images/icons/menu_Home.svg'
import AleLogo from '../images/icons/AleLogo.svg'
import Pagos from '../images/icons/icono-pagos.svg'
import Quote from '../images/icons/quote.svg'


export default ({currentPage}) => {
    const [menu, setMenu] = useState(false);
    const url = typeof window !== 'undefined' ? window.location.pathname : '';

    function showMenu() {
        setMenu(!menu)
    }

    return (
        <nav className={menu ? style.active : ''}>
            <Link to="/"><AleLogo/></Link>
            <button aria-label="Menu" onClick={(e) => showMenu()}>
                <span> </span>
                <span> </span>
                <span> </span>
            </button>
            <ul>
                <li><Link to="/en"><Home/><span>Home</span></Link></li>
                <li><Link to="/en/dealerships"><AleDealerships/><span>Dealerships</span></Link></li>
                <li><Link to="/en/mexjet"><AleMexJet/><span>MexJet</span></Link></li>
                <li><Link to="/en/preowned"><AlePreowned/><span>Preowned</span></Link></li>
                <li><Link to="/en/service-center"><AleService/><span>Service Center</span></Link></li>
                <li><Link to="/en/fbo-management"><AleFBO/><span>FBO & Management</span></Link></li>
                <li><Link to="/en/marine"><AleMarine/><span>Marine</span></Link></li>
                <li><p>EN</p><span><Link to={url}>English</Link> | <Link to={url.replace('en/', '')}>Spanish</Link></span></li>
                <li><Link to="/en/contact#form-contact"><AleContact/><span>Contact</span></Link></li>
            </ul>
        </nav>
    )
}

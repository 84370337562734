import React, {Component, useState} from "react"
import Slider from "react-slick"
import {graphql} from "gatsby"
import Layout from "../components/layout.en"
import style from "./a.module.scss"
import "slick-carousel/slick/slick.css"
import {GetList} from "../hooks/aeroplanesList"
import Img from "gatsby-image"
import Logos from "../images/icons/logo_Textron.svg"

//GALERIA
import FotoGaleria1 from "../images/foto_minigaleria1.jpg"
import FotoGaleria2 from "../images/foto_minigaleria2.jpg"

import Header from "../components/header";
import imgPage from "../images/headers/Logos_Ale_Dealerships.svg";
import ReactWOW from "react-wow";
import Form from "../components/form.en";


export default ({data}) => {
    var emptyArray = [];
    const aeroplaneList = GetList()
    //CESSNA
    var [cessna, setCessna] = useState([]);
    var cessnaList = [];

    aeroplaneList.map((item) => {
        if (item.node.frontmatter.brand === "cessna") {
            cessnaList.push(item)
        }
        return cessnaList
    })

    function filterCessna(e, t) {
        document.getElementById("beechcraft-list").reset();
        emptyArray = [];
        cessnaList.map((item) => {
            if (item.node.frontmatter.type === t) {
                emptyArray.push(item)
            }
            return true
        })
        setCessna(emptyArray);
        setShowing(false);
        setBeechcraft(beechcraftList);

    }

    //BEECHCRAFT
    var [beechcraft, setBeechcraft] = useState([]);
    var beechcraftList = [];
    aeroplaneList.map((item) => {
        if (item.node.frontmatter.brand === "beechcraft") {
            beechcraftList.push(item)
        }
        return beechcraftList
    })

    function filterBeechcraft(e, t) {
        document.getElementById("cessna-list").reset();
        emptyArray = [];
        beechcraftList.map((item) => {
            if (item.node.frontmatter.type === t) {
                emptyArray.push(item)
            }
            return true
        });
        setBeechcraft(emptyArray);
        setShowing(false);
        setCessna(cessnaList)
    }

    var [showing, setShowing] = useState(false);

    function showList() {
        setShowing(!showing)
    }

    const extraInfo = [
        {name: '', mail: 'info@ale.mx', phone: '5542020920'},
    ]

    return (
        <Layout clase={'aeroplane'} extraInfo={extraInfo}>

            <Header LogoPage={imgPage}/>

            <Img className={style.aeroCover} fluid={data.portada.childImageSharp.fluid}/>

            <ReactWOW animation='fadeInUp'>
                <div className={style.infoAeronaves}>
                    <div className={style.left}>
                        <SimpleSlider/>
                    </div>
                    <div className={style.right}>
                        <Logos/>
                        <p>Textron Aviation, a leading company in the aviation sector and home to the Beechcraft, Cessna brands.</p>
                        <p>Its wide range of products include Citation executive aircrafts, Beechcraft King Air and Cessna Caravan turboprops, Beechcraft and Cessna piston engine aircrafts; and the T-6 military training aircraft.</p>
                        <p>All of our products are backed by the industry's most capable service network that supports customers around the world.</p>
                        <Form align='bottom' options={['Textron: New Aircraft']}/>
                    </div>
                </div>
            </ReactWOW>
            <form className={`${style.aeroplaneList}`} id='cessna-list'>
                <h2>Cessna</h2>
                <h2>SELECT THE TYPE OF AIRCRAFT</h2>
                <div className={showing ? `${style.controls} ${style.showing}` : style.controls}>
                    <label>
                        <input type="radio" name='cessna' onClick={(event) => filterCessna(event, 'jets')}/>
                        <p>Jets</p>
                    </label>
                    <label>
                        <input type="radio" name='cessna' onClick={(event) => filterCessna(event, 'piston')}/>
                        <p>Pistons</p>
                    </label>
                    <label>
                        <input type="radio" name='cessna' onClick={(event) => filterCessna(event, 'turbo')}/>
                        <p>Turboprops</p>
                    </label>
                    <label>
                        <input type="radio" defaultChecked={true} name='cessna'
                               onClick={(event) => filterCessna(event, 'all')}/>
                        <p>All</p>
                    </label>
                    <button type={"button"} className={style.responsiveButtonA} onClick={(e) => showList()}>Seleccione</button>
                </div>
                <SliderAero aeroplanes={cessna.length <= 0 ? cessnaList : cessna}/>
            </form>


            <form className={`${style.aeroplaneList}`} id='beechcraft-list'>
                <h2>Beechcraft</h2>
                <h2>SELECT THE TYPE OF AIRCRAFT</h2>
                <div className={showing ? `${style.controls} ${style.showing}` : style.controls}>
                    <label>
                        <input type="radio" name='beechcraft' onClick={(event) => filterBeechcraft(event, 'piston')}/>
                        <p>Pistons</p>
                    </label>
                    <label>
                        <input type="radio" name='beechcraft' onClick={(event) => filterBeechcraft(event, 'turbo')}/>
                        <p>Turboprops</p>
                    </label>
                    <label>
                        <input type="radio" defaultChecked={true}
                               name='beechcraft' onClick={(event) => filterBeechcraft(event, 'all')}/>
                        <p>All</p>
                    </label>
                    <button type={"button"} className={style.responsiveButtonA} onClick={(e) => showList()}>Seleccione</button>

                </div>
                <SliderAero aeroplanes={beechcraft.length <= 0 ? beechcraftList : beechcraft}/>
            </form>

            <Img fluid={data.footer.childImageSharp.fluid}/>

        </Layout>
    )
}

class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            className: style.slider,
            slidesToScroll: 1,
        };
        return (
            <Slider {...settings}>
                <img src={FotoGaleria1} alt="Aerolineas Slider"/>
                <img src={FotoGaleria2} alt="Aerolineas Slider"/>
            </Slider>
        );
    }
}

class SliderAero extends Component {
    constructor(props) {
        super();
        this.state = {
            aeroplaneList: props.aeroplanes,
            toshow: 4
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.aeroplanes !== this.props.aeroplanes) {
            this.setState({aeroplaneList: this.props.aeroplanes});
            if (this.props.aeroplanes.length <= 4) {
                this.setState({toshow: this.props.aeroplanes.length})
            } else {
                this.setState({toshow: 4})
            }

        }
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: this.state.toshow,
            className: `${style.aeroSlider}`,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 840,
                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                    }
                }
            ]
        };
        return (
            <Slider {...settings}>
                {this.state.aeroplaneList.map((item) => {
                    return (
                        <div className={`${style.item}`}
                             key={item.node.frontmatter.slug}>
                            <Img fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                            <a target="_blank" rel="noopener noreferrer"
                               href={item.node.frontmatter.slug}>{item.node.frontmatter.title}</a>
                        </div>
                    )
                })}
            </Slider>
        );
    }

}


export const query = graphql`
    query ImagesAEn {
    portada: file(relativePath: {eq: "beechcraft/cover.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    footer: file(relativePath: {eq: "beechcraft/foto-2023-abajo.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Nav from './nav.en'
import 'normalize.css'
import "./layout.module.scss"
import "./layout-general.css"
import "animate.css/animate.css"
import "typeface-open-sans"
import Footer from "./footer.en";
import {ParallaxProvider} from 'react-scroll-parallax'

const Layout = ({children, clase = "class", footer = "default", extraInfo = null}) => {
    return (

        <ParallaxProvider>
            <Nav/>
            <main className={clase}>
                {children}
                <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=9G1XOomGJxt53hHUKLRJucbC58aeSpGWriko3SLOckBzec3d2ISbUktel7cP"></script></span>
                <Footer type={footer} extraInfo={extraInfo}/>
            </main>
        </ParallaxProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout

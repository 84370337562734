import React from "react"
import style from "./footer.module.scss"
import ALELogo from "../images/icons/AleLogoBlanco.svg"
import MarineLogo from "../images/icons/logo_Marine.svg"
import {Link} from "gatsby"
import Facebook from "../images/icons/facebook.svg"
import Twitter from "../images/icons/twitter.svg"
import Instagram from "../images/icons/instagram.svg"
//import Sello from "../images/sello.png";
import LogoWA from "../images/icons/wa.svg";
import LogoMail from "../images/icons/mail.svg";


const Footer = ({type, extraInfo}) => (

    <footer className={style[type]}>
        {type === 'default' &&
        <>
            <div className={style.info}>
                <ALELogo/><br/>
            </div>
            <div className={style.info}>
                <p><b>Aerolíneas Ejecutivas, S.A de C.V.</b></p>
                <p className={style.info}>
                    Corporate Office México, CDMX <br/>
                    Av. Paseo de la Reforma No. 2608 Piso 20. <br/>
                    Col. Lomas Altas C.P. 11950 <br/>
                    Toll free number: <span><a href="tel:800 712 0414">800 712 0414</a></span> <br/>
                    Sales and Service Information: <span><a href="tel:722 279 1718">722 279 1718</a></span> <br/>
                    <a href="tel:52 4209 0200"> Tel. 52 4209 0200</a>
                </p>
            </div>
            <div className={style.info}>
                <p><b>Aeropuerto internacional de Toluca.</b></p>
                <p className={style.info}>
                    Calle 2, Hangar 9 Toluca Edo. de Mex. C.P. 50200 <br/>
                    Toll free number <span><a href="tel:800 712 0415">800 712 0415</a></span> <br/>
                    Tel. <span><a href="tel:+52 (722) 279 1600">+52 (722) 279 1600</a></span>
                    <span className={style.as}>
                    <a href="mailto:info@ale.mx">info@ale.mx</a>
                    <a href="https://www.ale.mx">www.ale.mx</a>
                </span>
                </p>
            </div>
            <div className={style.info}>
                <p><b>Corporate Office Monterrey, N.L.</b></p>
                <p className={style.info}>
                    Aeropuerto Internacional del Norte <br/>
                    Carretera a Salinas Victoria Km 1.7. <br/>
                    Interior 10 y 11, Apodaca N.L. C.P 66050 <br/>
                    Toll free number <span><a href="tel:800 925 3236">800 925 3236</a></span> <br/>
                    Tel. <span><a href="tel:(81) 8288 7900">(81) 8288 7900</a></span>
                </p>
            </div>
            <div className={style.extraInfoMail}>
                {extraInfo &&
                <>
                    {extraInfo.map((item) => {
                        return (
                            <div className={style.item}>
                                <p>{item.name}</p> /
                                <p><a href={`mailto:`}>{item.mail}</a></p> /
                                <p><span><a href={'tel:'+item.phone}>{item.phone}</a></span></p>
                            </div>
                        )
                    })}
                </>
                }
            </div>
            <div className={style.extraInfo}>
                <div className={style.redes}>
                    <a aria-label="Facebook" href="https://www.facebook.com/aleaerolineasoficial/"><Facebook/></a>
                    <a aria-label="Twiiter" href="https://twitter.com/aleaerolineas"><Twitter/></a>
                    <a aria-label="Instagram" href="https://www.instagram.com/aleaerolineasoficial/"><Instagram/></a>
                </div>
                <Link to='/en/derechos'>Arco Rights</Link>
                <Link to='/en/privacidad'>Privacy Notice</Link>
                {/*<Link to='/'>Complaints</Link>*/}
            </div>
        </>
        }
        {type === 'marine' &&
        <>
            <div className={style.first}>
                <div className={style.info}>
                    <MarineLogo/>
                </div>
                <div className={style.extraInfo}>
                    <div className={style.redes}>
                        <a aria-label="Facebook" href="https://www.facebook.com/aleaerolineasoficial/"><Facebook/></a>
                        <a aria-label="Twiiter" href="https://twitter.com/aleaerolineas"><Twitter/></a>
                        <a aria-label="Instagram"
                           href="https://www.instagram.com/aleaerolineasoficial/"><Instagram/></a>
                    </div>
                    <Link to='/en/derechos'>Arco Rights</Link>
                    <Link to='/en/privacidad'>Privacy Notice</Link>

                </div>
            </div>
            <div className={style.info}>
                <p><b>Ale Marine</b></p>
                <p className={style.info}>
                    Av. Paseo de la Reforma No. 2608 Piso 20. <br/>
                    Col. Lomas Altas C.P. 11950 <br/>
                    Toll free number <span><a href="tel::800 712 0414">800 712 0414</a></span> <br/>
                    Sales and Service Information: <span><a href="tel:722 279 1718">722 279 1718</a></span> <br/>
                    Tel. <span><a href="tel:+52 (55) 4628 2213">+52 (55) 4628 2213</a></span> <br/>
                    <a href="mailto:info@princessyachts.mx"><b>info@princessyachts.mx</b></a>
                    <div className={style.extraInfoMail}>
                        {extraInfo &&
                        <>
                            {extraInfo.map((item) => {
                                return (
                                    <div className={style.item}>
                                        <p>{item.name}</p> /
                                        <p><a href={`mailto:`}>{item.mail}</a></p> /
                                        <p><span><a href={'tel:'+item.phone}>{item.phone}</a></span></p>
                                    </div>
                                )
                            })}
                        </>
                        }
                    </div>
                </p>
            </div>

        </>
        }
        {type === 'marine2' &&
        <>
            <div className={style.first}>
                <div className={style.info}>
                    <MarineLogo/>
                </div>
                <div className={style.extraInfo}>
                    <div className={style.redes}>
                        <a aria-label="Facebook" href="https://www.facebook.com/aleaerolineasoficial/"><Facebook/></a>
                        <a aria-label="Twiiter" href="https://twitter.com/aleaerolineas"><Twitter/></a>
                        <a aria-label="Instagram"
                           href="https://www.instagram.com/aleaerolineasoficial/"><Instagram/></a>
                    </div>
                    <Link to='/en/derechos'>Arco Rights</Link>
                    <Link to='/en/privacidad'>Privacy Notice</Link>
                </div>
            </div>
            <div className={style.info}>
                <p><b>Ale Marine</b></p>
                <p className={style.info}>
                    Av. Paseo de la Reforma No. 2608 Piso 20. <br/>
                    Col. Lomas Altas C.P. 11950 <br/>
                    Toll free number <span><a href="tel:800 712 0414">800 712 0414</a></span> <br/>
                    Sales and Service Information: <span><a href="tel:722 279 1718">722 279 1718</a></span> <br/>
                    Tel. <span><a href="tel:+52 (55) 4628 2213">+52 (55) 4628 2213</a></span>
                    <div className={style.extraInfoMail}>
                        {extraInfo &&
                        <>
                            {extraInfo.map((item) => {
                                return (
                                    <div className={style.item}>
                                        <p>{item.name}</p> /
                                        <p><a href={`mailto:`}>{item.mail}</a></p> /
                                        <p><span><a href={'tel:'+item.phone}>{item.phone}</a></span></p>
                                    </div>
                                )
                            })}
                        </>
                        }
                    </div>
                    <a href="mailto:info@ale.mx"><b>info@ale.mx</b></a> /
                    <a href="https://ale.mx"><b>www.ale.mx</b></a>
                </p>
            </div>

        </>
        }
        <div className={style.floatingLinks}>
            <a href="https://wa.me/5549440307" target={'_blank'} rel={'noreferrer'}>
                <LogoWA/>
            </a>
            <a href="mailto:info@ale.mx">
                <LogoMail/>
            </a>
        </div>
    </footer>

)


export default Footer
